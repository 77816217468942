var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageheading } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
      _c(
        "div",
        {
          staticStyle: { padding: "0px", margin: "0px" },
          attrs: { id: "body-content-area" },
        },
        [
          _c(
            "div",
            {
              staticStyle: { height: "100%", padding: "0px" },
              attrs: { id: "pagelayout" },
            },
            [
              _c("div", { staticClass: "document" }, [
                _c("iframe", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    src: "http://www.africau.edu/images/default/sample.pdf",
                    frameborder: "0",
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }